import {
  getMessaging as firebaseGetMessaging,
  getToken,
  deleteToken,
} from 'firebase/messaging';
import { env } from '@vette/common-utils';
import { app } from './app';

let _messaging: ReturnType<typeof firebaseGetMessaging>;
const getMessaging = () => {
  if (!_messaging) _messaging = firebaseGetMessaging(app);
  return _messaging;
};

export const obtainPushNotificationToken = async () => {
  return getToken(getMessaging(), {
    serviceWorkerRegistration:
      await window.navigator.serviceWorker.getRegistration(),
    ...(!env.isDevelopment() && {
      vapidKey: env.checkEnvVarValue('NEXT_PUBLIC_FIREBASE_VAPID_KEY'),
    }),
  });
};

export const deleteNotificationToken = async () => {
  return deleteToken(getMessaging());
};
