import { intervalToDuration } from 'date-fns';

export const utcTimestamp = () => {
  const date = new Date();
  return date.getTime();
};

export const utcDate = () => {
  return new Date();
};

export const formatUS = (date = utcDate()) => {
  return new Intl.DateTimeFormat('en-US').format(date);
};

export const millisecondsFromNow = (delayInSeconds: number) => {
  return delayInSeconds + Date.now() / 1000;
};

export const utcDateMinusDays = (days: number) => {
  return new Date(utcTimestamp() - days * 86400000);
};

export const utcFutureTimestamp = (deltaInterval: number | string) => {
  const nowTimestamp = utcTimestamp();
  const numericDeltaInterval = Number(deltaInterval);
  // This is a safety mechanism for accidental double calling of this function
  // deltaInterval should never be bigger than the current timestamp
  if (numericDeltaInterval > nowTimestamp) return numericDeltaInterval;
  return nowTimestamp + Number(numericDeltaInterval);
};

export const formatMillisecondsToTime = (milliseconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: milliseconds,
  });

  const doubleDigit = (number?: number) =>
    number ? `${number < 10 ? '0' : ''}${number}` : `00`;

  return `${doubleDigit(duration.hours)}:${doubleDigit(
    duration.minutes
  )}:${doubleDigit(duration.seconds)}`;
};
