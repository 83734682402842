import React, { useCallback, useEffect } from 'react';
import * as S from './styles';
import { TimerCountdown } from '../../common';
import {
  useAcceptVetterSessionMutation,
  useRejectVetterSessionMutation,
} from '@vette/data-access';
import { message } from '@vette/frontend-utils';
import { useAudio, usePrevious } from 'react-use';
import { useRouter } from 'next/router';

export const AcceptSessionModal: React.FC<{
  visible: boolean;
  timeout?: number;
  sessionId: string;
}> = ({ visible, timeout, sessionId }) => {
  const [acceptVetterSession] = useAcceptVetterSessionMutation();
  const [rejectVetterSession] = useRejectVetterSessionMutation();
  const router = useRouter();

  const showGeneralError = () => {
    message.error(
      'Something went wrong. Our team was notified and is intensively working on fixing the issue.'
    );
  };

  const prevVisibility = usePrevious(visible);
  const [audio, , controls] = useAudio({
    src: '/sounds/new_session.wav',
    loop: true,
  });

  useEffect(() => {
    if (visible && !prevVisibility) {
      controls.seek(0);
      controls.play();
    }

    if (!visible) {
      controls.pause();
    }
  }, [controls, visible, prevVisibility]);

  const onAccept = useCallback(async () => {
    const response = await acceptVetterSession({
      variables: {
        sessionId,
      },
    });

    if (
      response.data?.acceptVetterSession?.__typename === 'ValidationError' ||
      response.data?.acceptVetterSession?.__typename === 'NotFoundError'
    ) {
      showGeneralError();
    } else {
      await router.replace(`/interview/${sessionId}`);
    }
  }, [sessionId, acceptVetterSession, router]);

  const onReject = useCallback(async () => {
    const response = await rejectVetterSession({
      variables: {
        sessionId,
      },
    });

    if (
      response.data?.rejectVetterSession?.__typename === 'ValidationError' ||
      response.data?.rejectVetterSession?.__typename === 'NotFoundError'
    ) {
      showGeneralError();
    }
  }, [sessionId, rejectVetterSession]);
  ''.replace(/\//g, ' / ');

  return (
    <>
      {audio}
      <S.Modal
        visible={visible}
        centered
        closable={false}
        destroyOnClose={true}
        width={700}
        footer={[
          <S.AcceptButton
            aria-label="Accept Vette"
            size="large"
            key="acceptButton"
            onClick={onAccept}
          >
            Accept
          </S.AcceptButton>,
          <S.RejectButton
            aria-label="Reject Vette"
            size="large"
            key="rejectButton"
            onClick={onReject}
          >
            Reject
          </S.RejectButton>,
        ]}
        title="You have an incoming Vette"
      >
        <S.CountdownTitle>
          Remaining time to accept:{' '}
          {timeout && timeout > 0 && (
            <TimerCountdown targetTimestamp={timeout} />
          )}
        </S.CountdownTitle>
      </S.Modal>
    </>
  );
};
