import React, { useEffect, useRef } from 'react';
import { MessageType } from 'antd/lib/message';
import { useUserQuery } from '@vette/data-access';
import { message } from '@vette/frontend-utils';
import { useRouter } from 'next/router';

type Props = { userId: string };

export const RedirectToSettings: React.FC<Props> = ({ userId }) => {
  const router = useRouter();
  const messageRefs = useRef<{ [msgKey: string]: MessageType }>({});

  const { data } = useUserQuery({
    variables: { userId },
  });

  const { route } = router;

  useEffect(() => {
    if (data?.user?.__typename === 'User') {
      if (data.user.isProfileDataProvided) {
        for (const hideMessage of Object.values(messageRefs.current)) {
          hideMessage();
        }
        return;
      }

      const isSettingsPage = router.asPath === '/settings';
      const isTermsPage = router.asPath === '/terms';

      const msgKey = isSettingsPage.toString();
      const showMessage = () => {
        message.destroy();
        return message.info(
          'Please provide your contact information before navigating to other pages.'
        );
      };

      if (!isTermsPage)
        router.replace('/settings').then(() => {
          if (!!messageRefs.current?.[msgKey] && !isSettingsPage)
            messageRefs?.current[msgKey]();

          if (
            (!messageRefs.current?.[msgKey] && isSettingsPage) ||
            !isSettingsPage
          )
            messageRefs.current[msgKey] = showMessage();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, data]);

  return null;
};
