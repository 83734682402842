import React from 'react';
import { firebase as firebaseService } from '../..';
import { firebase as firebaseEntity } from '@vette/common-utils';

type Props = {
  fallback?: React.ReactNode;
  userId?: string;
  children: (
    userId: string,
    sessionId?: string,
    session?: firebaseEntity.Session
  ) => React.ReactNode;
};

export const VetterSession: React.FC<Props> = ({
  userId,
  children,
  fallback,
}) => {
  const { user } = firebaseService.useFirebase();

  const [sessionId, vettersWatchLoading] =
    firebaseService.database.useWatchRefState<string>(
      user && user.uid && `vetters/${user.uid}/sessionId`
    );

  const [session, sessionsWatchLoading] =
    firebaseService.database.useWatchRefState<firebaseEntity.Session>(
      sessionId && `sessions/${sessionId}`
    );

  if (!userId) {
    return <>{fallback || <div />}</>;
  }

  return (
    <>
      {!vettersWatchLoading &&
        !sessionsWatchLoading &&
        children(userId, sessionId, session)}
    </>
  );
};
