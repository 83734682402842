import { Button as BaseButton, Modal as BaseModal } from '../../common/atoms';
import styled from 'styled-components';

export const Modal = styled(BaseModal)``;

export const CountdownTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

export const AcceptButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.green40};
  color: ${({ theme }) => theme.colors.white};
  min-width: 148px;

  &:hover,
  &.ant-btn:not([disabled]):hover {
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      ${({ theme }) => theme.colors.green40};
  }

  &:active,
  &:focus {
    border: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      ${({ theme }) => theme.colors.green40};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const RejectButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red40};
  min-width: 148px;

  &:hover,
  &.ant-btn:not([disabled]):hover {
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      ${({ theme }) => theme.colors.red40};
  }

  &:active,
  &:focus {
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),
      ${({ theme }) => theme.colors.red40};
  }
`;
