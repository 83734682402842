import React, { useState } from 'react';
import { useInterval } from 'react-use';
import { date } from '@vette/common-utils';

type Props = {
  targetTimestamp: number;
  onFinish?: () => void;
};

export const TimerCountdown: React.FC<Props> = ({
  targetTimestamp,
  onFinish,
}) => {
  const calculateSeconds = () => {
    const utc = date.utcTimestamp();
    return Math.floor((targetTimestamp - utc) / 1000);
  };

  const currentSecondsTillFinish = calculateSeconds();
  const [seconds, setSeconds] = useState(currentSecondsTillFinish);

  useInterval(
    () => {
      if (seconds < 0) return;
      const secondsTillFinish = calculateSeconds();
      if (secondsTillFinish <= 0) {
        onFinish?.();
      }
      setSeconds(secondsTillFinish);
    },
    // Stop the timer once it runs out:
    currentSecondsTillFinish >= 0 ? 1000 : null
  );

  return <>{Math.max(seconds, 0)}</>;
};
