import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePreviousDistinct } from 'react-use';
import { firebase as firebaseEntity } from '@vette/common-utils';
import { message } from '@vette/frontend-utils';
import { SessionStatusEnum } from '@vette/data-access';

const EXPECTED_PREVIOUS_STATUSES = [
  SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY,
  SessionStatusEnum.SESSION_FINISHED_WITH_AN_ISSUE,
];

export const SessionEndWatcher: React.FC<{
  userId: string;
  sessionId?: string;
  session?: firebaseEntity.Session;
}> = ({ session, sessionId: firebaseSessionId, userId }) => {
  const router = useRouter();
  const previousStatus = usePreviousDistinct(session?.status);

  useEffect(() => {
    const { sessionId: urlSessionId } = router.query ?? {};

    if (
      urlSessionId &&
      router.asPath === `/interview/${urlSessionId}` &&
      previousStatus &&
      !session
    ) {
      localStorage.removeItem(`interview-${urlSessionId}`);

      if (previousStatus === SessionStatusEnum.LOOKING_FOR_VETTER) {
        // skip
      } else if (EXPECTED_PREVIOUS_STATUSES.some(s => s === previousStatus)) {
        router.replace('/dashboard#vette_completed');
      } else {
        router.replace(`/dashboard#vette_failed=${previousStatus}`).then(() => {
          previousStatus === SessionStatusEnum.WAITING_FOR_VETTER_TO_PRESS_DIGIT
            ? message.error(
                'We did not receive an input from your phone device. Please make sure to press the digit 1 on your phone device after picking up a call.'
              )
            : message.error(
                `Unfortunately, we were unable to connect you with the applicant. 
            We are working on getting another Vette to you!`
              );
        });
      }
    }
  }, [userId, session, previousStatus, firebaseSessionId, router]);

  return null;
};
