import { useCustomRouter } from '@vette/frontend-utils';
import { useCallback, useState } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { Space, Text } from '../atoms';
import { theme } from '../theme';
import * as S from './styles';

const ZENDESK_SNIPPET_KEY = process.env.NEXT_PUBLIC_ZENDESK_SNIPPET_KEY;

export const ZendeskWidget = () => {
  const { router, pages } = useCustomRouter();

  const [isZendeskReady, setIsZendeskReady] = useState(false);
  const [isMessengerOpened, setIsMessengerOpened] = useState(false);
  const [isStylesInitialized, setIsStylesInitialized] = useState(false);

  const initializeStyles = useCallback(() => {
    /* https://github.com/Ajhad1/Zendesk-Customizations/blob/main/Web-Widget/Add-CSS-To-Web-Widget/index.html */

    // Message window structure
    // <div>
    //   <iframe>
    //     #document
    //     <html>
    //       <head></head>
    //       <body></body>
    //     </html>
    //   </iframe>
    // </div>

    const iframe = Array.prototype.slice
      .call(window.document.getElementsByTagName('iframe'))
      .find(e => e.title === 'Messaging window') as
      | HTMLIFrameElement
      | undefined;

    const styleHead = document.createElement('style');
    styleHead.innerHTML = `
      .eUGVYL {
        border-radius: 20px;
        box-shadow: 1px 1px 5px ${theme.colors.purple40};
        margin: 3px;
        margin-bottom: 70px;
        margin-right: 20px;
      }
      .fNJzme {
        border-radius: 20px 20px 0 0;
      }
      .kmeNDk {
        visibility: hidden;
      }
    `;

    const styleDiv = document.createElement('style');
    styleDiv.innerHTML = `
      #home {
        box-shadow: unset !important;
      }
    `;

    const head = iframe?.contentWindow?.document.head;
    const body = iframe?.contentWindow?.document.body;
    const html = head?.parentElement;
    const div = iframe?.parentElement;

    if (!iframe || !head || !html || !div || !body) {
      console.warn('Unable to locate Zendesk iframe to adjust styling');
      return;
    }

    head.appendChild(styleHead);
    div.appendChild(styleDiv);
    html.style.backgroundColor = 'transparent';
    body.style.backgroundColor = 'transparent';

    setIsStylesInitialized(true);
  }, []);

  const isWidgetVisibleOnCurrentPage =
    router.asPath === '/' ||
    router.asPath === pages.vetter.settings ||
    router.asPath.startsWith(pages.vetter.dashboard);

  if (!isWidgetVisibleOnCurrentPage && isMessengerOpened) {
    ZendeskAPI('messenger', 'close');
    setIsMessengerOpened(false);
  }

  if (!ZENDESK_SNIPPET_KEY) return null;

  return (
    <>
      {isWidgetVisibleOnCurrentPage && isZendeskReady && (
        <S.CustomLauncherButton
          type="primary"
          size="large"
          onClick={() => {
            if (!isStylesInitialized) {
              ZendeskAPI(
                'messenger:set',
                'zIndex',
                theme.styles.zIndexPriority.topMost
              );

              setTimeout(() => {
                initializeStyles();
              }, 0);
            }

            ZendeskAPI('messenger', isMessengerOpened ? 'close' : 'open');
            setIsMessengerOpened(!isMessengerOpened);
          }}
        >
          <Space direction="horizontal" $centerVertically>
            {!isMessengerOpened ? (
              <>
                <S.ChatOpenIcon />
                <Text $size={15} $weight={600} $color={theme.colors.purple10}>
                  Chat
                </Text>
              </>
            ) : (
              <>
                <S.ChatCloseIcon />
                <Text $size={15} $weight={600} $color={theme.colors.purple10}>
                  Close
                </Text>
              </>
            )}
          </Space>
        </S.CustomLauncherButton>
      )}

      <Zendesk
        defer
        zendeskKey={ZENDESK_SNIPPET_KEY}
        onLoaded={() => {
          ZendeskAPI('messenger', 'close');
          setTimeout(() => {
            setIsZendeskReady(true);
          }, 2000);
        }}
      />
    </>
  );
};
