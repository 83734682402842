import styled from 'styled-components';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../atoms';

export const ChatOpenIcon = styled(ChatBubbleOutlineIcon)`
  width: 25px;
  color: ${({ theme }) => theme.colors.purple10};
  transform: scale(-1, 1);
`;

export const ChatCloseIcon = styled(CloseIcon)`
  width: 25px;
  color: ${({ theme }) => theme.colors.purple10};
`;

export const CustomLauncherButton = styled(Button)`
  position: absolute;
  right: 35px;
  bottom: 25px;
  z-index: ${({ theme }) => theme.styles.zIndexPriority.topOverMost};
  width: 100px;
  height: 45px;

  &.ant-btn-primary {
    background: ${({ theme }) => theme.colors.purple60};
  }
`;
